import { reactive } from 'vue';
import { Adapter, AdapterHealth, AdapterHealthState } from './model';
import ConfigController from '@/clients/config';
import OpsController from '@/clients/ops';
import { countBy, isEqual, uniq } from 'lodash';
import * as Apollo from './service.apollo';
import { formatDateTime } from '@/pages/utils';
import { ConnectionInfo } from '../ops/model';

interface StateInterface {
  adapters: Record<string, Adapter>;
  lastUpdated: string | null;
}

export default class Controller {
  private static instance: Controller;
  private state: StateInterface;
  private refreshTimeout: NodeJS.Timeout | null = null;

  private constructor() {
    /*
     * STATE
     */
    this.state = reactive({
      adapters: {},
      lastUpdated: null,
    });
  }

  static get Instance(): Controller {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }

    return Controller.instance;
  }

  public get lastUpdated(): string | null {
    return this.state.lastUpdated;
  }

  public refreshHealthStatus = async () => {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
    this.refreshTimeout = setTimeout(
      () => this.refreshHealthStatus(),
      10 * 1000
    );

    const labId = OpsController.Instance.selectedLabId;
    if (labId) {
      await OpsController.Instance.dispatchGetLabConnectionHealth(labId);
      await this.dispatchGetAdapters(labId);
    }

    this.state.lastUpdated = formatDateTime(new Date()).datetime;
  };

  private async dispatchGetAdapters(labId: string): Promise<Adapter[]> {
    let lab = OpsController.Instance.getLab(labId);
    if (
      !lab.adapterIds ||
      lab.adapterIds.length === 0 ||
      !lab.connections ||
      lab.connections.length === 0
    ) {
      lab = await OpsController.Instance.dispatchGetLab(labId);
    }

    if (lab?.id) {
      const configuredAdapterIds = lab.adapterIds || [];
      const connectedAdapterIds =
        lab.connections?.map((c) => c.client.name) || [];
      const disabledAdapterIds =
        lab.bannedConnections?.map((b) => {
          // banned connections are a fully formed "scope" string of the form
          // <ns>:<org_id>:<lab_id>:<adapter_id>
          const idx = b.lastIndexOf(':');
          if (idx >= 0 && idx < b.length - 1) {
            return b.substring(idx + 1);
          }
          return b;
        }) || [];

      const allAdapterIds = uniq([
        ...configuredAdapterIds,
        ...connectedAdapterIds,
        ...disabledAdapterIds,
      ]);

      const connectedAdapterIdsByCount = countBy(connectedAdapterIds);

      await Promise.all(
        allAdapterIds.map(async (a) => {
          const config =
            await ConfigController.Instance.dispatchGetAdapterConfig(labId, a);
          const connection = lab.connections?.find((c) => c.client.name === a);

          const isDisabled = disabledAdapterIds.includes(a);
          const isConnected = !!connection && !isDisabled;
          const isConfigured = configuredAdapterIds.includes(a);

          const adapter: Adapter = {
            id: a,
            name: a,
            state: {
              connected: isConnected,
              disabled: isDisabled,
              configured: isConfigured,
            },
            labId,
            numInstances: isConnected ? connectedAdapterIdsByCount[a] || 1 : 1,
            version: connection?.server?.version || '--',
            scope: connection?.scope || undefined,
          };

          if (isConnected) {
            adapter.health = getAdapterHealth(adapter, connection);
          }

          if (
            isConfigured &&
            adapter.config?.configValuesVersion !== config?.configValuesVersion
          ) {
            adapter.config = config;
          }

          const existingAdapter = this.state.adapters[a];
          if (!isEqual(existingAdapter, adapter)) {
            this.state.adapters[a] = adapter;
          }
        })
      );

      Object.keys(this.state.adapters).forEach((a) => {
        if (!allAdapterIds.includes(a)) {
          delete this.state.adapters[a];
        }
      });

      return Object.values(this.state.adapters);
    }
    return [];
  }

  public async dispatchDeleteAdapter(id: string) {
    const adapter = this.getAdapter(id);
    if (adapter) {
      const success =
        await ConfigController.Instance.dispatchDeleteAdapterConfig(id);
      if (success) {
        delete this.state.adapters[id];
      }
      return success;
    }
    return false;
  }

  public async dispatchDisableAdapter(
    adapterId: string,
    labId: string,
    disable = true
  ) {
    const success = await Apollo.setBannedConnection(adapterId, labId, disable);
    const adapter = this.getAdapter(adapterId);
    if (success && adapter) {
      adapter.state.disabled = disable;
    } else if (success) {
      this.state.adapters[adapterId] = {
        ...this.state.adapters[adapterId],
        numInstances: 0,
        state: {
          connected: false,
          configured: false,
          disabled: disable,
        },
      };
    }
  }

  public async dispatchKillAdapter(scope: string): Promise<boolean> {
    return Apollo.killAdapter(scope);
  }

  public getAdapters(labId: string): Adapter[] {
    return Object.values(this.state.adapters).filter((a) => a.labId === labId);
  }

  public getAdapter(id: string): Adapter | undefined {
    return this.state.adapters[id];
  }
}

const getAdapterHealth = (
  adapter: Adapter,
  connection: ConnectionInfo
): AdapterHealth => {
  const getAggregateHealthStatus = (
    connectionHealth: boolean,
    healthInfoHealth: boolean
  ): AdapterHealthState => {
    if (connectionHealth && healthInfoHealth) {
      return AdapterHealthState.ALL_CONNECTIONS_HEALTHY;
    } else if (connectionHealth || healthInfoHealth) {
      return AdapterHealthState.SOME_CONNECTIONS_HEALTHY;
    } else if (!connectionHealth && !healthInfoHealth) {
      return AdapterHealthState.NO_CONNECTIONS_HEALTHY;
    }
    return AdapterHealthState.UNKNOWN;
  };

  const connectionHealth = adapter.state.connected;
  const clientHealth = !!connection?.client?.healthInfo?.every(
    (hi) =>
      !hi.value.localeCompare('ok', navigator.language, {
        sensitivity: 'accent',
      })
  );
  const clientHealthStatus = (): AdapterHealthState => {
    if (
      connection?.client?.healthInfo?.every(
        (c) =>
          !c.value.localeCompare('ok', navigator.language, {
            sensitivity: 'accent',
          })
      )
    ) {
      return AdapterHealthState.ALL_CONNECTIONS_HEALTHY;
    } else if (
      connection?.client?.healthInfo?.some(
        (hi) =>
          !hi.value.localeCompare('ok', navigator.language, {
            sensitivity: 'accent',
          })
      )
    ) {
      return AdapterHealthState.SOME_CONNECTIONS_HEALTHY;
    }
    return AdapterHealthState.NO_CONNECTIONS_HEALTHY;
  };

  return {
    clientHealthInfo: connection?.client.healthInfo || [],
    clientHealthStatus: clientHealthStatus(),
    aggregateHealth: getAggregateHealthStatus(connectionHealth, clientHealth),
  };
};
