import { gql } from '@apollo/client/core';
import { apollo } from '../service.apollo';

export async function setBannedConnection(
  adapterId: string,
  labId: string,
  banned: boolean
): Promise<boolean> {
  const response = await apollo.mutate<{ setBannedConnection: boolean }>({
    variables: {
      connectionId: adapterId,
      labId,
      banned,
    },
    mutation: gql`
      mutation SetBannedConnection(
        $connectionId: ID!
        $labId: ID!
        $banned: Boolean
      ) {
        setBannedConnection(
          connectionId: $connectionId
          labId: $labId
          banned: $banned
        )
      }
    `,
  });
  if (response.data?.setBannedConnection) {
    return response.data.setBannedConnection;
  }
  return false;
}

export async function killAdapter(scope: string): Promise<boolean> {
  const response = await apollo.mutate<{ killAdapter: boolean }>({
    variables: {
      scope,
    },
    mutation: gql`
      mutation KillAdapter($scope: String!) {
        killAdapter(scope: $scope)
      }
    `,
  });
  if (response.data?.killAdapter) {
    return response.data.killAdapter;
  }
  return false;
}
