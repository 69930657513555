import { EntityConfiguration } from '../config';

export interface AdapterState {
  connected: boolean;
  configured: boolean;
  disabled: boolean;
}

export interface Adapter {
  id: string;
  name: string;
  labId: string;
  state: AdapterState;
  config?: EntityConfiguration;
  numInstances: number;
  version: string;
  health?: AdapterHealth;
  scope?: string;
}

export interface AdapterHealth {
  clientHealthInfo: Record<string, string>[];
  clientHealthStatus?: AdapterHealthState;
  aggregateHealth: AdapterHealthState;
}

export enum AdapterHealthState {
  ALL_CONNECTIONS_HEALTHY = 'ALL_CONNECTIONS_HEALTHY',
  SOME_CONNECTIONS_HEALTHY = 'SOME_CONNECTIONS_HEALTHY',
  NO_CONNECTIONS_HEALTHY = 'NO_CONNECTIONS_HEALTHY',
  NO_ADAPTORS = 'NO_ADAPTORS_CONNECTED',
  UNKNOWN = 'UNKNOWN',
}
